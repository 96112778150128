import React from 'react';

import * as ContentDesign from 'src/components/layouts/content/theme';
import { useAxiosNow } from 'src/hooks/axios/useAxios';

import { LoadingMany } from 'src/components/design-system/loading';
import InfoAlert from 'src/components/design-system/alerts/info';
import { useConfig } from 'src/components/configuration';

import routes from 'src/config/routes';

import View from './view';

const shouldDisplayReinscriptionBanner = (famille, config) => {
  if (!famille || !famille.enfants) return false;

  const reinscriptionOuverte =
    Boolean(config.reinscription_ouverte) && famille.isLastSeasonMember;

  const isInscriptionOpen =
    reinscriptionOuverte ||
    (Boolean(config.reinscription_ca_ouverte) && famille.is_membreCA) ||
    config.inscription_ouverte;

  return (
    isInscriptionOpen &&
    !famille.enfants.some((e) => e.inscriptions && e.inscriptions.length > 0)
  );
};

const Dashboard = () => {
  const { requestState } = useAxiosNow({
    url: routes.userFamille(),
  });

  const requestStateConfig = useConfig();

  return (
    <LoadingMany
      requestStates={[requestState, requestStateConfig]}
      errorComponent={() => (
        <ContentDesign.P>Une erreur s'est produite.</ContentDesign.P>
      )}
    >
      {([rs, rsConfig]) => {
        const { data } = rs.data;
        const {
          data: { data: config },
        } = rsConfig;
        if (data && config) {
          return (
            <>
              {shouldDisplayReinscriptionBanner(data, config) && (
                <InfoAlert
                  message="Une nouvelle saison est ouverte."
                  className="mt-4"
                  link={{
                    label: 'Inscrivez-vous',
                    to: '/famille/inscription',
                  }}
                />
              )}
              <View famille={data} />
            </>
          );
        } else {
          return <ContentDesign.P>Une erreur s'est produite.</ContentDesign.P>;
        }
      }}
    </LoadingMany>
  );
};

export default Dashboard;
