import React from 'react';
import map from 'lodash/map';

import P from 'src/components/design-system/p';
import H3 from 'src/components/design-system/h3';
import { default as StyledLink } from 'src/components/design-system/link';
import { SecondaryTable } from 'src/components/design-system/table';

import Header from 'src/components/design-system/header';

import { convertDateISOToFr } from 'src/utils/date';
import { formatFamilyName } from 'src/utils/famille';
import { getYearFromISO } from '../../../utils/date';

const getEnfantLines = (enfants) =>
  enfants.map((e) => [
    <div>
      {e.prenom} {e.nom}
    </div>,
    <div className="text-right">{convertDateISOToFr(e.date_naissance)}</div>,
  ]);

const inscriptionsByYear = (inscriptions, enfants) => {
  return inscriptions.reduce((byYear, i) => {
    const year = `${i.saison.annee_debut}-${i.saison.annee_fin}`;
    const line = getInscriptionLine(i, enfants);

    if (byYear[year]) byYear[year].push(line);
    else byYear[year] = [line];

    return byYear;
  }, {});
};

const getInscriptionLine = (inscription, enfants) => [
  enfants.find((e) => e.id === inscription.enfants_id).prenom,
  inscription.date_certificat_medical ? (
    <div className="text-right">
      Certificat fourni le{' '}
      {convertDateISOToFr(inscription.date_certificat_medical)}
    </div>
  ) : (
    <div className="text-red-500 text-right">Certificat non fourni</div>
  ),
];

const getPaiementLines = (paiements) =>
  paiements.map((p) => [
    convertDateISOToFr(p.created_at),
    p.mode_paiement,
    <span className="block w-full text-right">{p.prix_paye} euros</span>,
  ]);

const ViewFamille = ({ famille }) => {
  return (
    <>
      <div className="mt-4 overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-0">
          <dl>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
              <dt className="text-sm font-medium text-gray-500 leading-5">
                Parent 1
              </dt>
              <dd className="mt-1 text-sm text-gray-900 leading-5 sm:mt-0 sm:col-span-2">
                <span className="capitalize">{famille.parent1_prenom}</span>{' '}
                {famille.parent1_nom.toUpperCase()}
                <P>{famille.tel_principal}</P>
              </dd>
            </div>

            {famille.parent2_nom && (
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                <dt className="text-sm font-medium text-gray-500 leading-5">
                  Parent 2
                </dt>
                <dd className="mt-1 text-sm text-gray-900 leading-5 sm:mt-0 sm:col-span-2">
                  <span className="capitalize">{famille.parent2_prenom}</span>{' '}
                  {famille.parent2_nom.toUpperCase()}
                  {famille.tel_secondaire && <P>{famille.tel_secondaire}</P>}
                </dd>
              </div>
            )}
            <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
              <dt className="text-sm font-medium text-gray-500 leading-5">
                E-mail(s)
              </dt>
              <dd className="mt-1 text-sm text-gray-900 leading-5 sm:mt-0 sm:col-span-2">
                <ul className="list-disc list-inside">
                  <li>{famille.mail_principal}</li>
                  {famille.mail_secondaire && (
                    <li>{famille.mail_secondaire}</li>
                  )}
                </ul>
              </dd>
            </div>
            <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
              <dt className="text-sm font-medium text-gray-500 leading-5">
                Adresse postale
              </dt>
              <dd className="mt-1 text-sm text-gray-900 leading-5 sm:mt-0 sm:col-span-2">
                <P>{famille.adresse}</P>
                <P>
                  {famille.code_postal} {famille.ville}
                </P>
              </dd>
            </div>
            <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
              <dt className="text-sm font-medium text-gray-500 leading-5">
                Enfant(s)
              </dt>
              <dd className="mt-1 text-sm text-gray-900 leading-5 sm:mt-0 sm:col-span-2">
                {famille.enfants && famille.enfants.length > 0 && (
                  <SecondaryTable
                    className=""
                    lines={getEnfantLines(famille.enfants)}
                  />
                )}
              </dd>
            </div>

            <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
              <dt className="text-sm font-medium text-gray-500 leading-5">
                Inscription(s)
              </dt>
              <dd className="mt-1 text-sm text-gray-900 leading-5 sm:mt-0 sm:col-span-2">
                {famille.inscriptions &&
                  famille.inscriptions.length > 0 &&
                  map(
                    inscriptionsByYear(famille.inscriptions, famille.enfants),
                    (i, k) => (
                      <>
                        <H3 className="">{k}</H3>
                        <SecondaryTable className="my-2" lines={i} />
                      </>
                    )
                  )}
              </dd>
            </div>

            <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
              <dt className="text-sm font-medium text-gray-500 leading-5">
                Paiement(s)
              </dt>
              <dd className="mt-1 text-sm text-gray-900 leading-5 sm:mt-0 sm:col-span-2">
                {famille.paiements && famille.paiements.length > 0 && (
                  <SecondaryTable
                    className=""
                    lines={getPaiementLines(famille.paiements)}
                  />
                )}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
};

export default ViewFamille;
