import React from 'react';
import Dashboard from 'src/components/famille/dashboard';
import { withGuard } from 'src/components/admin/guard';
import FamilleLayout from 'src/components/layouts/famille';
import SEO from 'src/components/seo';

const IndexPage = ({ userProfile }) => (
  <FamilleLayout userProfile={userProfile}>
    <SEO title="Espace adhérent - mon profil" />
    <Dashboard userProfile={userProfile} />
  </FamilleLayout>
);

export default withGuard(IndexPage);
