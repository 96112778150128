import React from 'react';
import ContentLayout from './content';
import * as ContentDesign from 'src/components/layouts/content/theme';
import Tabs from '../design-system/tab';
import { Link } from 'gatsby';

const links = [
  { label: 'Mon compte', to: '/famille/' },
  { label: 'Edition', to: '/famille/edit' },
  { label: 'Mot de passe', to: '/famille/password' },
];

const FamilleLayout = ({ children, ...props }) => (
  <ContentLayout {...props} className="w-full">
    <ContentDesign.H2>Espace adhérent</ContentDesign.H2>
    <div className="min-w-full flex items-baseline w-full">
      <Tabs links={links} className="mt-4 flex-grow" />

      <Link className="text-red-600 ml-6" to="/logout">
        Déconnexion
      </Link>
    </div>
    <hr className="mt-2" />
    {children}
  </ContentLayout>
);

export default FamilleLayout;
